import './features.css';

import Layout from 'components/layout';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import React, { useState } from 'react';
import { Link } from 'gatsby';
import ai from 'images/ai.svg'
import aiBlurred from 'images/aiBlurred.svg';
import background from 'images/background.svg';
import background2 from 'images/background2.svg';
import blurred_seamless from 'images/blurred_seamless.svg';
import commission from 'images/commission.svg';
import commissionBlurred from 'images/commissionBlurred.svg';
import crm from 'images/crm.svg';
import crmBlurred from 'images/crmBlurred.svg';
import data from 'images/data.svg';
import dataBlurred from 'images/dataBlurred.svg';
import deals from 'images/deals.svg';
import dealsBlurred from 'images/dealsBlurred.svg';
import flexible from 'images/flexible.svg';
import flexibleBlurred from 'images/flexibleblurred.svg';
import icon from 'images/icon.svg';
import payroll from 'images/payroll.svg';
import payrollBlurred from 'images/payrollBlurred.svg';
import plan from 'images/plan.svg';
import planBlurred from 'images/planBlurred.svg';
import quick from 'images/quick.svg';
import quickBlurred from 'images/quickBlurred.svg';
import real from 'images/real.svg';
import realBlurred from 'images/realBlurred.svg';
import seamless from 'images/seamless.svg';
import support from 'images/support.svg';
import supportBlurred from 'images/supportBlurred.svg';
import visual from 'images/visual.svg';
import visualBlurred from 'images/visualBlurred.svg';
import axios from 'axios';

const Features = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');

    const resetFormData = () => {
        setCompanyName('');
        setEmail('');
        setName('');
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: name,
            email: email,
            company: companyName,
        };
        submitData(data);
        resetFormData();
    };

    const submitData = async (data) => {
        const options = {
            method: "POST",
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: "https://script.google.com/macros/s/AKfycbwu-r4XdO1qoQ55E3bQ-Z0Mq9PQpgrUhrjOGLjsF3NUnGPC2dLUwUHDx0LycsYivdZnKg/exec?sheetName=features",
            data: JSON.stringify(data)
        }
        //eslint-disable-next-line
        const response = await axios(options)
    }

    return (
        <Layout>
            <div>
                <Helmet>
                    <link rel="icon" href={icon} />
                    <title>Modules Designed to Motivate Reps and Boost Sales</title>
                    <meta name="description" content="Flexible Quota Design, Visual Comp Plan Builder, Sync with Major CRM, Real-Time Reporting, Payroll Integration, and Commission Control in one place." />
                </Helmet>
                <div className="home">
                    <div>
                        <p className="title">Each Module Designed to Motivate Reps and Boost Commissionable Sales</p>
                        <p className="description">Supercharge your sales team’s performance with a better comp plan using science and psychology of incentives.</p>
                        <Link href='/quotacal/demo'>
                            <Button className="button" type="primary" >Schedule a Demo</Button>
                        </Link>
                    </div>
                </div>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={{ span: 6 }}>
                        <div>
                            <img className="Image" src={background} alt="something went wrong" />
                            <img className="Image2" src={blurred_seamless} alt="something went wrong" />
                            <img className="Image3" src={seamless} alt="something went wrong" />
                        </div>
                    </Col>
                    <Col xs={12} md={{ span: 6 }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Simple and Seamless</p>
                            <p className='Desc'>Commission software designed by revenue experts and engineers. We know you hate complexity, Let our engine do the hard work while you can focus on sales.</p>
                            <ul className="ul">
                                <li>AI based recommendations based on performance</li>
                                <li>Scenario Modeling and Budget accordingly</li>
                                <li>Seamless data Integrations to make change easier</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Flexible Quota Design</p>
                            <p className='Desc'>Sales quota calculation frameworks differ from company to company. Our application is capable to handle all sort of use-cases when it comes to comp plan design.</p>
                            <ul className="ul">
                                <li>Setup Complex Comp Plans in Minutes</li>
                                <li>Track Commissions and activities real time</li>
                                <li>Reports and Analytics which is not boring</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={flexibleBlurred} className="Image5" alt="something went wrong" />
                        <img src={flexible} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={visualBlurred} alt="something went wrong" />
                        <img className="Image3" src={visual} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Visual Comp Plan Builder</p>
                            <p className='Desc'>QuotaCal offers a visual builder that helps the sales leaders implement the incentive structure for sales	without the need of much technical understanding or coding.</p>
                            <ul className="ul">
                                <li>Flow chart like builder</li>
                                <li>Drag and drop elements</li>
                                <li>Integrates with external applications</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Informative Dashboard</p>
                            <p className='Desc'>Customizable dashboard with widgets such as Goal Tracker, Earnings, ToDoList, etc. Each user can have different dashboard as per their access or information requirement.</p>
                            <ul className="ul">
                                <li>Premade drag and drop widgets </li>
                                <li>User can design his/her dashboard</li>
                                <li>Create custom widgets as per your requirements</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={blurred_seamless} className="Image5" alt="something went wrong" />
                        <img src={seamless} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={crmBlurred} alt="something went wrong" />
                        <img className="Image3" src={crm} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Sync with Major CRM</p>
                            <p className='Desc'>CRMs are of course the brain of any successful sales process. QuotaCal can be the heart (where motivation happens), and integrates seamlessly  all major CRMs.</p>
                            <ul className="ul">
                                <li>Integrates with Salesforce, HubSpot and Zoho</li>
                                <li>Complete sales data flows from CRM to QuotaCal</li>
                                <li>Custom integration with niche CRMs</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Commission Tracker</p>
                            <p className='Desc'>Let the sales reps and leaders track the commissions with graphical representation of earnings over time and the attainment module which shows the results that drive commission.</p>
                            <ul className="ul">
                                <li>Earning module to track periodic commissions</li>
                                <li>Attainment module to track deliverables</li>
                                <li>Graphical presentation for better understanding</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={commissionBlurred} className="Image5" alt="something went wrong" />
                        <img src={commission} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={planBlurred} alt="something went wrong" />
                        <img className="Image3" src={plan} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Comp Plan Templates</p>
                            <p className='Desc'>To build your comp plans, you can use the premade templates or design from scratch. Share the plan templates and assign plans to users or teams with ease. </p>
                            <ul className="ul">
                                <li>Flexibility in designing plans</li>
                                <li>Progress bar to shows plan completion rate by phase</li>
                                <li>Define earning rule using various parameters</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Real Time Reporting</p>
                            <p className='Desc'>Zero delay in commission and bonus calculation and realtime reports displayed on the dashboard. Create custom reports based on the use-case of sales department and personnel.</p>
                            <ul className="ul">
                                <li>Real time commission statements</li>
                                <li>Key Performance Indicators  in reports</li>
                                <li>Report widgets in dashboard</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={realBlurred} className="Image5" alt="something went wrong" />
                        <img src={real} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={dealsBlurred} alt="something went wrong" />
                        <img className="Image3" src={deals} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>End-to-End Transparency</p>
                            <p className='Desc'>The system has been designed to give end-to-end visibility on how the commissions and bonuses are calculated so that there is no conflict or doubt between the stakeholders. </p>
                            <ul className="ul">
                                <li>Complete transparency across stakeholders</li>
                                <li>Calculation statements for future references</li>
                                <li>Zero chance of conflict or doubt</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Payroll Integration</p>
                            <p className='Desc'>QuotaCal integrates with your Payroll software so that the comissions are disbursed to sales reps on time and hence avoiding any dissatisfaction and questioning.</p>
                            <ul className="ul">
                                <li>Integrates with major payroll tools</li>
                                <li>Custom integrations via API</li>
                                <li>On time disbursement of Incentives and Bonus</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={payrollBlurred} className="Image5" alt="something went wrong" />
                        <img src={payroll} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={aiBlurred} alt="something went wrong" />
                        <img className="Image3" src={ai} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>AI Recomendations</p>
                            <p className='Desc'>QuotaCal uses Artificial Intelligence engine to understand the data present in the system and come up with intelligent suggestions to improve overall process, earn better incentives, etc.</p>
                            <ul className="ul">
                                <li>AI based recommendations based on performance</li>
                                <li>Process improvement suggestions for sales leaders</li>
                                <li>Suggestions for sales reps to maximize their incentives</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Data Exports</p>
                            <p className='Desc'>Export reports and raw data from the system for both backup and analysis purpose. Create access levels of users and give selective permissions to whomever is authorised to take an export.</p>
                            <ul className="ul">
                                <li>Export of reports and raw data</li>
                                <li>Selective access to users for data export</li>
                                <li>Export in CSV, PDF, XML and Excel formatsg</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={dataBlurred} className="Image5" alt="something went wrong" />
                        <img src={data} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={crmBlurred} alt="something went wrong" />
                        <img className="Image3" src={crm} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Open API and Integrations</p>
                            <p className='Desc'>QuotaCal offers Open API for 3rd party application integrations. The integration works seamlessly with enterprise grade security so that the data is safe.</p>
                            <ul className="ul">
                                <li>Safe data exchange via API</li>
                                <li>Integrate with CRM, Payroll and the in-house product</li>
                                <li>Marketplace that offers standard integration with CRM</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Commission Control</p>
                            <p className='Desc'>Cap the commissions for scenarios where sales reps may find loopholes in the commission plan and earn unreasonable commissions which may be disruptive to the system.</p>
                            <ul className="ul">
                                <li>Make adjustments to commission plan when needed</li>
                                <li>Track unreasonable Commissions drawn by reps</li>
                                <li>Manually override commission calculations if needed</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={dealsBlurred} className="Image5" alt="something went wrong" />
                        <img src={deals} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={supportBlurred} alt="something went wrong" />
                        <img className="Image3" src={support} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Support and HelpDesk</p>
                            <p className='Desc'>QuotaCal gives proactive customer support during regular business hours (and outside business hours in special cases) via Phone, Chat, Email, Helpfiles and Video Demonstrations.</p>
                            <ul className="ul">
                                <li>Support Channels: Phone, Chat, Email</li>
                                <li>Help files and video demostrations</li>
                                <li>Remote assistance via video conference</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Quick Dispute Resolution</p>
                            <p className='Desc'>Resolves disputes between sales reps regarding attribution (and disputes with sales leaders and admins regarding commissions and disbursements) quickly with data.</p>
                            <ul className="ul">
                                <li>Present data to resolve conflicts</li>
                                <li>Leave no room for dispute</li>
                                <li>Intervention of leaders and sales ops in case of dispute</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={quickBlurred} className="Image5" alt="something went wrong" />
                        <img src={quick} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={crmBlurred} alt="something went wrong" />
                        <img className="Image3" src={crm} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Workflows & Automations</p>
                            <p className='Desc'>Build workflows inside the software and include automations that involve integrations such as 3rd party tools such as CRM, Payroll, Emailing tool, etc.</p>
                            <ul className="ul">
                                <li>Reminders for followup, deal closure, etc.</li>
                                <li>Updating fields in CRM and vice-versa</li>
                                <li>Alerts on zero incentive possibility to reps</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={{ span: 12, order: 'last' }} md={{ span: 6, order: 'first' }}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Zero Error Payouts</p>
                            <p className='Desc'>Payouts to reps need to be accurate so that the reps stay motivated to bring more sales. We at QuotaCal strive for Zero Error in Commission calculation and hence zero error.</p>
                            <ul className="ul">
                                <li>Accurate commission calculation</li>
                                <li>Manual intervention by admins in case of rare errors</li>
                                <li>Revenure ops audit for error checking</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <img src={background2} className="Image4" alt="something went wrong" />
                        <img src={payrollBlurred} className="Image5" alt="something went wrong" />
                        <img src={payroll} className="Image6" alt="something went wrong" />
                    </Col>
                </Row>
                <Row style={{ marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Col xs={12} md={6}>
                        <img className="Image" src={background} alt="something went wrong" />
                        <img className="Image2" src={dealsBlurred} alt="something went wrong" />
                        <img className="Image3" src={deals} alt="something went wrong" />
                    </Col>
                    <Col xs={12} md={6}>
                        <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                            <p className='Head'>Sales Ops Audit</p>
                            <p className='Desc'>Provisions for audit by revenue ops team periodically so that you can ensure the comp plans and payouts are in line with the overall objective of the organisation.</p>
                            <ul className="ul">
                                <li>Payout audit, Deals Audit, Log Audit</li>
                                <li>Audit on loopholes in commission model</li>
                                <li>Alerts on zero incentive possibility to reps</li>
                            </ul>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 1 }} style={{ textAlign: 'right', marginBottom: '50px' }}>
                        <div>
                            <p className="Head"> Don't Wait. Book your demo today! </p>
                            <p className="Desc">Unwind your need of creating complicated commission data. try Quotacal today.</p>
                            <Link href='/quotacal/demo'>
                                <Button className="button" type="primary" >Schedule a Demo</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 4, offset: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Inria Sans', fontSize: '20px' }}>
                            <Form style={{ marginBottom: '30px' }} onSubmit={handleSubmit} >
                                <Form.Control className="formStyle" value={name} onChange={(e) => setName(e.target.value)} required placeholder="First Name" />
                                <Form.Control className="formStyle" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="Email" />
                                <Form.Control className="formStyle" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required placeholder="Company Name" />
                                <Button className="button" type="submit">
                                    Submit</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div >
        </Layout>
    )
};

export default Features;